.CalendarView .CalendarContainer {
  max-height: 88vh;
  overflow-y: auto;
}
.CalendarView .CalendarContainer .CalendarWrapper {
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.CalendarView .CalendarContainer .CalendarWrapper::-webkit-scrollbar {
  display: none;
}

.CalendarView .CalendarContainer .fc-timegrid-event-harness {
  margin-right: 2px;
}

.CalendarView .CalendarContainer .group-chip {
  background-color: white;
  font-weight: bold;
  color: #002147;
  font-size: 14px;
}

.react-datepicker-wrapper.w-240 {
  width: 240px;
}

.fc-timegrid-event-harness {
  -webkit-box-shadow: 8px 8px 15px 0px rgba(0,0,0,0.38); 
  box-shadow: 8px 8px 15px 0px rgba(0,0,0,0.38);
}