//Vars 
:root {
  --rad: .5em;
  --dur: .3s;
  --color-dark: #2f2f2f;
  --color-light: #fff;
  --color-brand: #800080;
  --font-fam: 'Lato', sans-serif;
  --height: 1.5em;
  --btn-width: 6rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
}


// Main styles
.search-form{
    display: flex;
    justify-content: flex-end;
    form {
        position: relative;
        width: 60%;
        background: var(--color-brand);
        border-radius: var(--rad);
      }
      input, button {
        height: var(--height);
        font-family: var(--font-fam);
        border: 0;
        color: var(--color-dark);
        font-size: 1em;
      }
      input[type="search"] {
        outline: 0; // <-- shold probably remove this for better accessibility, adding for demo aesthetics for now.
        width: 100%;
        background: var(--color-light);
        padding: 1.3em;
        border-radius: var(--rad);
        appearance: none; //for iOS input[type="search"] roundedness issue. border-radius alone doesn't work
        transition: all var(--dur) var(--bez);
        transition-property: width, border-radius;
        z-index: 1;
        position: relative;
        border:2px solid #800080
      }
      button {
        display: none; // prevent being able to tab to it
        position: absolute;
        top: 19%;
        right: 0;
        width: var(--btn-width);
        background: var(--color-brand);
        border-radius: 0 var(--rad) var(--rad) 0;
        font-size: 1.2em;
        color:white;
        &:hover{
            cursor:pointer;
        }
      }
      input:not(:placeholder-shown) {
        border-radius: var(--rad) 0 0 var(--rad);
        width: calc(100% - var(--btn-width));
        + button {
          display: block;
        }
      }
      label {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
      }
}

