.PrimaryButton {
  color: white !important;
  background-color: #2196f3 !important;
  height: min-content;
  width: min-content;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
}

.PrimaryButton:hover {
  background-color: #1976d2!important;
}