.SecondaryButton {
  color: black;
  background-color: rgb(224, 224, 224)!important;
  height: min-content;
  width: min-content;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.SecondaryButton:hover {
  background-color: #bdbdbd!important;
}